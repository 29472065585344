<template>
  <vca-card class="text-center">
    <h1 v-if="!error">{{ $t("confirm.redirect") }}</h1>
    <h1 v-else>{{ $t("confirm.error.message") }}</h1>
  </vca-card>
</template>

<script>
export default {
  name: "EmailConfirm",
  data() {
    return {
      error: false,
    };
  },
  props: {
    code: {
      type: String,
      default: "",
    },
  },
  methods: {
    submit() {
      this.mail = true;
    },
    login() {
      this.$router.push({ path: "/login", query: { scope: this.scope } });
    },
  },
  scope() {
    return this.$store.state.scope ? this.$store.state.scope : "community";
  },
  mounted() {
    this.$store.commit("user/reset_email_code", this.code);
    this.$store
      .dispatch({ type: "user/email_finish" })
      .then(() => {
        var that = this;
        setTimeout(function () {
          /*if (data.data.message === "no_token") {
                          // TODO get scope from data.scope
                          that.$router.push({name: "Login", query: {scope: 'move4water', msg: data.message, source: 'confirm'}})
                      } else if(data.payload.message === "has_token") {
                          window.location = data.payload.redirect_url + "?code=" + data.payload.code
                      }*/
          that.login();
        }, 3000);
      })
      .catch(() => {
        this.error = true;
      });
  },
};
</script>
